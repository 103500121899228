/* eslint camelcase: 0 */
import { defineStore } from 'pinia';
import { sortDailyReads } from '@@/components/DailySnows/SortDailySnowUtils';

export const useDailyReadsStore = defineStore('dailyReads', {
  state: () => ({}),

  actions: {
    async fetchDailyReads(payload = {}) {
      const { $api } = useNuxtApp();
      const {
        filter = 'All',
        sort,
        userLocation = null,
      } = payload;

      let { daily_reads } = await $api.get('/daily-reads');

      if (filter !== 'All') {
        daily_reads = daily_reads.filter((dr) => dr.categories.find((c) => c.name === filter));
      }

      sortDailyReads(daily_reads, sort, userLocation);

      return daily_reads;
    },

    async fetchDailyRead(payload = {}) {
      const { $api } = useNuxtApp();
      const {
        postId = null,
        slug = null,
        withContent = false,
      } = payload;

      const url = [
        '/daily-reads',
        slug ? `${slug}` : '',
        postId ? `post/${postId}` : '',
        withContent ? 'content' : '',
      ].filter((item) => item).join('/');

      const { daily_read } = await $api.get(url, {
        // _temporary_? band aid for cloudflare issue I cannot understand
        // this will enforce cache never lives longer than 2 mins
        ts: Math.floor(new Date().getTime() / 120_000),
      });

      return {
        article: daily_read,
      };
    },
  },
});
